@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@layer base {
  :root {
    --icon-color: #30496b;
    --background: linear-gradient(to bottom, #30496b, #30b8d2);
  }

  body {
    font-family: Nexa Sans-Serif;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
@font-face {
  font-family: Nexa, Sans-Serif;
  src: url(assets/fonts/Nexa\ Bold.otf), url(assets/fonts/Nexa\ Regular.otf),
    url(assets/fonts/Nexa\ Light.otf);
}

.MuiDrawer-root {
  z-index: 100 !important;
}
.btn_bg_custom {
  background: linear-gradient(to bottom, #30496b, #30b8d2);
}
.bg-custom {
  background: linear-gradient(to right, #30496b 20%, #30b8d2 80%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.bg-custom:hover {
  /* background: linear-gradient(to right, #30496b 20%, #30b8d2 80%); */
  background: linear-gradient(to right, #3498db, #2c3e50);
}
.google-custom {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.hoverCustom:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}
.loginBgImg {
  background-image: url(/src/assets/images/auth/authbg.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: bottom right;
  background-position-x: 108%;
  background-position-y: 220%;
}
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #30496b, #30b8d2);
  border-radius: 10px;
}
.app-env-scroll::-webkit-scrollbar {
  width: 8px;
}
.metrics::-webkit-scrollbar {
  width: 8px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 6px !important;
}
.clock-hanger {
  background: linear-gradient(to bottom, #30496b, #30b8d2);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.clock {
  display: flex;
  /* width: 15rem;
  height: 15rem; */
  position: relative;
  padding: 0.5rem;
  /* border: 10px solid; */
  border-color: #307493;
  border-radius: 50%;
  margin: 8px auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.outer-clock-face {
  position: relative;
  background: #fff;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.outer-clock-face::after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
}

.outer-clock-face::after,
.outer-clock-face::before,
.outer-clock-face .marking {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  z-index: 0;
  left: 49%;
}
.outer-clock-face .marking {
  background: linear-gradient(to right, #30496b, #30b8d2);
  width: 2px;
}

.outer-clock-face .marking.marking-one {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
}

.outer-clock-face .marking.marking-two {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  transform: rotate(60deg);
}
.outer-clock-face .marking.marking-five {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.outer-clock-face .marking.marking-three {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  transform: rotate(120deg);
}

.outer-clock-face .marking.marking-four {
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  transform: rotate(150deg);
}
.inner-clock-face {
  background: #fff;
  padding: 0.5rem;
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  z-index: 1;
}

.inner-clock-face::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border-radius: 18px;
  margin-left: -9px;
  margin-top: -6px;
  background: linear-gradient(to right, #30496b, #30b8d2);
  z-index: 11;
}
.importCSV {
  max-width: 184px;
}
.importCSV::-webkit-file-upload-button {
  visibility: hidden;
}
.importCSV::-moz-file-upload-button {
  visibility: hidden;
}
.importCSV::before {
  content: "Choose CSV";
  width: 100%;
  display: inline-block;
  /* background: linear-gradient(to bottom, #30496b, #30b8d2); */
  background: #3a833a;
  color: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  outline: none;
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 11pt;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.sample-csv-btn {
  display: flex;
  align-items: center;
  text-align: center;
  background: linear-gradient(to bottom, #30496b, #30b8d2);
  font-weight: 500;
  font-size: 11pt;
  color: #fff;
  border-radius: 10px;
  padding: 10px 33px;
}
.edit-custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.edit-custom-file-input::before {
  content: "Select from gallery";
  width: 93%;
  display: inline-block;
  background: linear-gradient(to bottom, #30496b, #30b8d2);
  color: #fff;
  border-radius: 10px;
  padding: 15px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 10pt;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select from gallery";
  display: inline-block;
  background: linear-gradient(to bottom, #30496b, #30b8d2);
  color: #fff;
  border-radius: 10px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 10pt;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
  text-align: center;
}

.custom-edit-logo::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-edit-logo::before {
  content: "Choose logo";
  display: inline-block;
  color: #000;
  width: 100%;
  border-radius: 10px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 10pt;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
#country-select-demo::placeholder {
  color: #000 !important;
}

.dot {
  display: inline-block;
  background: var(--background);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 1rem;
}
.notification-card {
  border-left: 2px solid transparent;
}
.notification-card:hover {
  border-left: 2px solid var(--icon-color);
}
.apps-env-icon {
  object-fit: cover;
}

.grid-1-5 {
  border: 1px solid #eaecf0;
  border-radius: 16px;
  min-height: 550px;
  padding: 1.25em;
  position: relative;
  text-align: center;
  width: 80%;
  margin: auto;
  transition: all 300ms ease;
  padding: 40px 32px 32px;
}
.grid-1-5:hover {
  box-shadow: 0 12px 16px 0 rgba(16, 24, 40, 0.08);
}

.half-black {
  background: #fff;
  opacity: 0.3;
}

.masking {
  width: 100%;
  /* min-height: 90%; */
  position: relative;
  z-index: 1;
}
.masking:after {
  content: "";
  background: #fff;
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.parent {
  width: 100%;
  min-height: 550px;
  position: relative;
  z-index: 1;
}
.parent .current-plan {
  position: absolute;
  top: 78%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: #e9ab49;
  transition: all 300ms ease;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  border-radius: 8px;
  font-size: 1.1rem;
  padding: 12px 20px;
  text-decoration: none;
  width: 50%;
  text-transform: capitalize;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border: 1px solid transparent;
  background: #fcf2e4;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.parent:after {
  content: "";
  background: #ffffff99;
  width: 100%;
  min-height: 550px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.child {
  position: relative;
  z-index: 0;
}
.second-Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}

@media screen and (max-width: 600px) {
  .grid-1-5:nth-child(odd) {
    clear: left;
  }
  .grid-1-5 {
    width: 100%;
  }
}
@media screen and (min-width: 800px) {
  .grid-1-5:nth-child(3n + 1) {
    clear: left;
  }
  .grid-1-5:nth-child(odd) {
    clear: none;
  }
}

.grid-1-5 h3 {
  font-family: Nexa "Sans-Serif";
  color: #101828;
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: 0.0625em;
  margin: 0 0 0.3333333333em;
}
.grid-1-5 h4 {
  font-family: Nexa "Sans-Serif";
  margin-bottom: 4px;
  color: #101828;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.grid-1-5 p {
  color: #475467;
  line-height: 24px;
  font-size: 16px;
  margin: 0 0 1.5em;
}

.ul {
  color: #796583;
  font-size: 0.75em;
  list-style-type: none;
  padding: 0;
}
.ul .li {
  display: flex;
  align-items: center;
}
.ul .li svg {
  margin-right: 15px;
}
.ul .li span {
  display: inline-block;
  color: #292f36;
  font-size: 18px;
  line-height: 28px;
  text-align: start;
}

.get-started-button {
  background-color: #3d4349;
  transition: all 300ms ease;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  padding: 12px 20px;
  position: absolute;
  bottom: 1.25em;
  text-decoration: none;
  width: 70%;
  text-transform: capitalize;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.get-started-button:hover {
  background-color: #3d4349;
}
sup,
.small {
  font-size: 0.6125em;
}
.height-formula {
  height: calc(100vh - 19vh);
}
.under-circle {
  border: 1px solid #d5eedf;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upper-circle {
  border: 1px solid #d5eedf;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-png {
  position: absolute;
  bottom: -100vh;
}

.strt_free_trial {
  background: var(--background);
  color: white;
  text-align: center;
  border: 0px solid;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 16px;
}
.bg-card {
  padding: 25px;
  background: url(./assets/images/icons/credit.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}
.paypal-bg-img {
  height: 50%;
  width: 100%;
  padding: 25px;
  background: url("./assets/images/icons/paypal.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}
.iiz__btn {
  display: none !important;
}
.heading-block {
  font-size: 30px;
  font-weight: 400;
}
.logoanim {
  animation: pulsate 5s ease-in-out;
  animation-iteration-count: infinite;
  width: 30%;
  object-fit: cover;
}
@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.rti--tag {
  line-break: anywhere;
}

.schedule_table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.schedule_table thead tr th {
  text-align: start;
  padding: 8px;
  border-bottom: 2px solid rgb(238, 238, 238);
}
.schedule_table tbody tr td {
  border: 1px solid transparent;
  text-align: left;
  padding: 10px;
}

.react-tabs__tab {
  padding: 12px 27px !important;
}

.mac-download-btn {
  background: linear-gradient(to bottom, #30496b, #30b8d2);
  color: #fff;
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 20px;
  text-align: center;
  border-radius: 13px;
  display: inline-block;
  padding: 10px;
  font-weight: 600;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  padding: 10px 25px;
}
.mac-instruction {
  list-style: none;
}
.mac-instruction li {
  display: flex;
  align-items: start;
  margin-bottom: 10px;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
}
.mac-instruction li span {
  /* width: 34px;
    height: 34px; */
  color: #30b8d2;
  display: inline-flex;
  justify-content: start;
  text-align: start;
  border-radius: 50px;
  align-items: start;
  /* border: 1px solid #30b8d2; */
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  /* line-height: 34px; */
  margin-right: 5px;
}
.join-Coummunity {
  background-color: #f08887;
  color: #fff;
  text-transform: capitalize;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  border-radius: 13px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 10px;
  border: unset;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.join-Coummunity:hover {
  background-color: #f37775;
  color: #fff;
}

.common-btn-bg {
  background: linear-gradient(to bottom, #30496b, #30b8d2);
  color: #fff;
}
.daily-btn {
  border-right: 1px solid #9e9e9e;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
.week-btn {
  border-right: 1px solid #9e9e9e;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
.monthly-btn {
  border-right: 1px solid #9e9e9e;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
.daily-btn:hover {
  border: 0;
  border-right: 1px solid #9e9e9e;
}
.week-btn:hover {
  border: 0;
  border-right: 1px solid #9e9e9e;
}
.monthly-btn:hover {
  border: 0;
  border-right: 1px solid #9e9e9e;
}
.total-btn:hover {
  border: 0;
}
.total-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}
.apps-search-outline {
  margin-left: 0px !important;
  width: 400px !important;
}
.apps-search-outline .MuiOutlinedInput-notchedOutline {
  border-radius: 20px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.both-btns-border {
  border: 1px solid #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.new-soft-btn,
.new-block-btn {
  border: none !important;
}
.new-soft-btn {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
.new-block-btn {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
.react-tabs__tab--selected .tab-title {
  font-weight: 700;
}
.goog-te-combo {
  border: 2px solid #000;
  border-radius: 5px;
}
.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  font-size: 0px !important;
}
.checkLogin_modal:focus-visible {
  outline: none !important;
  box-shadow: unset !important;
}
.btn-logo {
  width: 80px;
  object-fit: contain;
  aspect-ratio: 3 / 2;
  height: 70px;
}

/* customStyles.css */
.apexcharts-menu {
  width: 10rem !important; /* Ensures the menu is always 10rem wide */
}

.apexcharts-menu-open {
  width: 10rem !important; /* You can also specifically ensure it stays this width when open */
}
.lets_go_hover {
  background: linear-gradient(to bottom, #30496b, #30b8d2);
}
.lets_go_hover:hover {
  background: linear-gradient(to right, #3498db, #2c3e50);
}
.only_hover:hover {
  background: linear-gradient(to right, #3498db, #2c3e50);
}
