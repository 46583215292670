.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  background-color: #000000;
  opacity: 0.6;
}
